import React from 'react';
import './App.css';
import Backup from './BackupX';

function App() {
  
  return (
    <div className="App">    
   <Backup/>
    </div>
  );
}

export default App;