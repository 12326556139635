import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';
const driverLicenseFields = {
  "DAE": "Name Suffix",
  "DAF": "Name Prefix",

  //"DAM": "Residence Street Address2",
  //"DAN": "Residence City",
  //"DAO": "Residence Jurisdiction Code",
  //"DAP": "Residence Postal Code",

  "DAR": "License Classification Code",
  "DAS": "License Restriction Code",
  "DAT": "License Endorsements Code",
  "DAV": "Height in CM",
  "DAX": "Weight in KG",
  "DBE": "Issue Timestamp",
  "DBF": "Number of Duplicates",
  "DBH": "Organ Donor",
  "DBI": "Non-Resident Indicator",
  "DBK": "Social Security Number",
  "DBL": "Date Of Birth",
  "DBM": "Social Security Number",
  "DBR": "Suffix",
  "DBS": "Prefix",
  "DCL": "Race Ethnicity",
  "DCM": "Standard vehicle classification",
  "DCN": "Standard endorsement code",
  "DCO": "Standard restriction code",
  "DCP": "Jurisdiction specific vehicle classification description",
  "DCQ": "Jurisdiction-specific",
  "DCR": "Jurisdiction specific restriction code description",
  "DDC": "HazMat Endorsement Expiry Date",
  "DDH": "Under 18 Until",
  "DDI": "Under 19 Until",
  "DDJ": "Under 21 Until",
  "DDK": "Organ Donor Indicator",
  "DDL": "Veteran Indicator",
  "PAA": "Permit Classification Code",
  "PAB": "Permit Expiration Date",
  "PAC": "Permit Identifier",
  "PAD": "Permit IssueDate",
  "PAE": "Permit Restriction Code",
  "PAF": "Permit Endorsement Code",
  "ZVA": "Court Restriction Code",

  "DAA": "FULL NAME",
  "DAC": "FIRST NAME",
  "DCT": "FIRST NAME",
	"DBG": "FIRST NAME",
	"DBP": "FIRST NAME",
  "DAD": "MIDDLE NAME",
	"DBQ": "MIDDLE NAME",
  "DAB": "LAST NAME",
  //"DBN": "LAST NAME",
  "DBO": "LAST NAME",
  "DCS": "LAST NAME",
  "DCU": "Name Suffix",
  "DBN": "Name Suffix", 

  //"DAG": "STREET ADDRESS",
  //"DAH": "STREET ADDRESS",
  //"DAL": "STREET ADDRESS",  
  //"DAI": "CITY",
  //"DAJ": "STATE",
  //"DAK": "ZIPCODE",
  //"DCG": "COUNTRY",  

  "DAG": "MAIL ADDRESS 1",
  "DAH": "MAIL ADDRESS 2",
  "DAI": "MAIL CITY",
  "DAJ": "MAIL STATE",
  "DAK": "MAIL ZIPCODE",

  "DAL": "STREET ADDRESS 1",
  "DAM": "STREET ADDRESS 2",
	"DAP": "STREET ZIPCODE",
  "DAN": "STREET CITY",
	"DAO": "STREET STATE",

  "DAQ": "ID NUMBER",
  //"DCJ": "ID NUMBER",
  "DBJ": "ID NUMBER",  
  "DCK": "INVENTORY CONTROL",
  "DCA": "CLASS",
  "DCB": "RESTRICTIONS",
  "DCD": "ENDORSEMENTS",
  "DCF": "DOCUMENT DISCRIMINATOR",
  "DCH": "VEHICLE CODE",
  "DBA": "EXPIRATION DATE",
  "DBB": "DATE OF BIRTH",
  "DCI": "PLACE OF BIRTH",
  "DBC": "GENDER",
  "DBD": "ISSUED DATE",
  "DAU": "HEIGHT",  
  "DCE": "WEIGHT",
  "DAW": "WEIGHT",    
  "DAY": "EYE COLOR",
  "DAZ": "HAIR COLOR",
  "ZWA": "CONTROL NUMBER",
  "ZWB": "WA SPECIFIC ENDORSEMENT",
  "ZWC": "TRANSACTION TYPE",
  "ZWD": "UNDER 18 UNTIL",
  "ZWE": "UNDER 21 UNTIL",
  "ZWF": "REVISION DATE",
  "DDE": "FAMILY NAME TRUNCATION",
  "DDF": "FIRST NAME TRUNCATION",
  "DDG": "MIDDLE NAME TRUNCATION",
  "DDA": "COMPLIANCE TYPE",
  "DDB": "CARD REVISION DATE",
  "DDD": "LIMITED INDICATOR",
};
const extractNumbers = (str) => {
  return str.replace(/\D/g, '');
};

const styleDate = (dateStr) => {
  const day = dateStr.slice(0, 2);
  const month = dateStr.slice(2, 4);
  const year = dateStr.slice(4);
  return `${day}/${month}/${year}`;
};

const extractEyeColorCode = (eyeColorCode) => {
  switch (eyeColorCode) {
    case "BLK": return "BLACK";
    case "BLU": return "BLUE";
    case "BRO": return "BROWN";
    case "GRY": return "GRAY";
    case "GRN": return "GREEN";
    case "HAZ": return "HAZEL";
    case "OTH": return "OTHER";
    case "MAR": return "MAROON";
    default: return "UNKNOWN";
  }
};

const generateOutputMap = (parsedData) => {
  let map = {};
  Object.keys(parsedData).forEach(tag => {
    const key = driverLicenseFields[tag] || tag;
    map[key] = parsedData[tag];
  });
  return map;
};

const parseDriverLicense = (input) => {
  const tags = Object.keys(driverLicenseFields).join('|'); // Create a regex pattern of all tags
  const pattern = new RegExp(`(${tags})(?=[A-Z0-9])`, 'g'); // Create a regex to match the tags
  const output = input.replace(pattern, '\n$1').trim();

  const lines = output.split('\n');
  const parsedData = {};

  lines.forEach(line => {
    const tag = line.substr(0, 3);
    const value = line.substr(3).trim();
    if (driverLicenseFields[tag]) {
      // Handle case where there might be multiple values for the same tag
      if (parsedData[driverLicenseFields[tag]]) {
        parsedData[driverLicenseFields[tag]] += ' ' + value;
      } else {
        parsedData[driverLicenseFields[tag]] = value;
      }
    }
  });

  return parsedData;
};

export default function BackupX() {
  const [licenseData, setLicenseData] = useState('');
  
  const handleInputChange = (event) => {
    setLicenseData(event.target.value);
  };

  const fillPDF = async () => {
    try {
      const parsedData = parseDriverLicense(licenseData);
      const useableMap = generateOutputMap(parsedData);
      //console.log(useableMap)
     // const inputPdfPath = 'https://raw.githubusercontent.com/UNSORTEDTHINGS/pdf/main/Lic.pdf';

      const inputPdfPath = 'https://raw.githubusercontent.com/UNSORTEDTHINGS/pdf/main/VL02.pdf';
      const existingPdfBytes = await fetch(inputPdfPath).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();

      const license = {
        driverLicNumber: useableMap["ID NUMBER"] || "",
        lastName: useableMap["LAST NAME"] || "",
        middleName: useableMap["MIDDLE NAME"] || "",
        firstName: useableMap["FIRST NAME"] || "",

        mailingAddress: useableMap["MAIL ADDRESS 1"] || "",
        mailingCity: useableMap["MAIL CITY"] || "",
        mailingState: useableMap["MAIL STATE"] || "",
        mailingZipCode: useableMap["MAIL ZIPCODE"] || "",

        streetAddress: useableMap["STREET ADDRESS 1"] || "",
        streetCity: useableMap["STREET CITY"] || "",
        streetState: useableMap["STREET STATE"] || "",
        streetZipCode: useableMap["STREET ZIPCODE"] || "",

        dateOfBirth: useableMap["DATE OF BIRTH"] || "",
        eyeColor: useableMap["EYE COLOR"],
        weight: useableMap["WEIGHT"] || ""
      };

      const fieldsToUpdate = [
        { fieldId: 'VERMONT LICENSE PERMIT or NON DRIVER ID CARD NUMBER', value: license.driverLicNumber },
        { fieldId: 'Last Name', value: license.lastName },
        { fieldId: 'Middle Name', value: license.middleName },
        { fieldId: 'First Name', value: license.firstName },
        
        { fieldId: 'Text1.0', value: license.mailingAddress },
        { fieldId: 'State.0', value: license.mailingState },
        { fieldId: 'City or Town.0', value: license.mailingCity },
        { fieldId: 'Zip Code.0', value: license.mailingZipCode.slice(0, 5) },

        { fieldId: 'Text1.1', value: license.streetAddress },
        { fieldId: 'State.1', value: license.streetState },
        { fieldId: 'City or Town.1', value: license.streetCity },
        { fieldId: 'Zip Code.1', value: license.streetZipCode.slice(0, 5) },

        { fieldId: 'Date of Birth MMDDYYYY', value: styleDate(license.dateOfBirth) },
        { fieldId: 'Eye Color', value: extractEyeColorCode(license.eyeColor) },
        { fieldId: 'Weight', value: extractNumbers(license.weight) + " LB" },
      ];


      for (const { fieldId, value } of fieldsToUpdate) {
        const field = form.getTextField(fieldId);
        if (field) {
          field.setText(value);
          //field.setFontSize(18);
        }
      }

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
     // console.log('PDF filled successfully!');
    } catch (error) {
      console.error('Failed to decode and fill PDF form:', error);
    }
  };
  
  const handlePrintClick = () => {
    fillPDF();
  };
  return (
    <div>
    <div className="InputWindow">
      <input
        className='input-data'
        value={licenseData}
        onChange={handleInputChange}
        placeholder="Enter data..."
      />
      <br />
      <button className='button-15' onClick={handlePrintClick}>Print</button>
    </div>
  </div>
  )
}
